import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../../environments/environment';

export const OauthConfig: AuthConfig = {
  // Url of the Identity Provider
  // issuer: 'https://alkogtidentity.azurewebsites.net',
  issuer: environment.issuer,

  // URL of the SPA to redirect the user to after login
  // redirectUri: window.location.origin + '/index.html',
  // redirectUri: 'http://localhost:4200/oauth',
  redirectUri: environment.oauthCallbackUrl,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: 'ServiceDashboard',

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  dummyClientSecret: 'LgZYcSmc41TMH4G',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid offline_access alkoCustomerId alkoCulture roles introspection',

  showDebugInformation: true,

  customQueryParams: {
    alkoCulture: 'DE',
  },

  loginUrl: environment.logoutUrl,
  logoutUrl: environment.logoutUrl,
  tokenEndpoint: environment.tokenEndpoint,
  // postLogoutRedirectUri: 'http://localhost:4200/home',
  postLogoutRedirectUri: environment.oauthCallbackUrl,

  skipIssuerCheck: true,
  // sessionChecksEnabled: true,

  // useSilentRefresh: true,
  useSilentRefresh: false,
  // silentRefreshTimeout: 5 * 60 * 1000,
  // silentRefreshRedirectUri: environment.tokenEndpoint,
  // silentRefreshRedirectUri: environment.oauthCallbackUrl,

  checkOrigin: false,
  // disablePKCE: true,
};

console.log('[CONFIG] silentRefreshRedirectUri:', environment.oauthCallbackUrl);
console.log('[CONFIG] redirectUri:', environment.oauthCallbackUrl);

// {
//     "issuer": "https://idp.staging.alko-garden.net",
//     "jwks_uri": "https://idp.staging.alko-garden.net/.well-known/openid-configuration/jwks",
//     "authorization_endpoint": "https://idp.staging.alko-garden.net/connect/authorize",
//     "token_endpoint": "https://idp.staging.alko-garden.net/connect/token",
//     "userinfo_endpoint": "https://idp.staging.alko-garden.net/connect/userinfo",
//     "end_session_endpoint": "https://idp.staging.alko-garden.net/connect/endsession",
//     "check_session_iframe": "https://idp.staging.alko-garden.net/connect/checksession",
//     "revocation_endpoint": "https://idp.staging.alko-garden.net/connect/revocation",
//     "introspection_endpoint": "https://idp.staging.alko-garden.net/connect/introspect",
//     "device_authorization_endpoint": "https://idp.staging.alko-garden.net/connect/deviceauthorization",
//     "frontchannel_logout_supported": true,
//     "frontchannel_logout_session_supported": true,
//     "backchannel_logout_supported": true,
//     "backchannel_logout_session_supported": true,
//     "scopes_supported": [
//         "openid",
//         "profile",
//         "alkoCustomerId",
//         "alkoCulture",
//         "alkoGroups",
//         "roles",
//         "offline_access"
//     ],
//     "claims_supported": [
//         "sub",
//         "name",
//         "family_name",
//         "given_name",
//         "middle_name",
//         "nickname",
//         "preferred_username",
//         "profile",
//         "picture",
//         "website",
//         "gender",
//         "birthdate",
//         "zoneinfo",
//         "locale",
//         "updated_at",
//         "alkoCustomerId",
//         "alkoCulture",
//         "alkoGroups",
//         "roles"
//     ],
//     "grant_types_supported": [
//         "authorization_code",
//         "client_credentials",
//         "refresh_token",
//         "implicit",
//         "password",
//         "urn:ietf:params:oauth:grant-type:device_code"
//     ],
//     "response_types_supported": [
//         "code",
//         "token",
//         "id_token",
//         "id_token token",
//         "code id_token",
//         "code token",
//         "code id_token token"
//     ],
//     "response_modes_supported": [
//         "form_post",
//         "query",
//         "fragment"
//     ],
//     "token_endpoint_auth_methods_supported": [
//         "client_secret_basic",
//         "client_secret_post"
//     ],
//     "id_token_signing_alg_values_supported": [
//         "RS256"
//     ],
//     "subject_types_supported": [
//         "public"
//     ],
//     "code_challenge_methods_supported": [
//         "plain",
//         "S256"
//     ],
//     "request_parameter_supported": true
// }
