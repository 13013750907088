import {
  Access,
  HistoryData,
  PimInformation,
  Thing,
  ThingType,
} from '@al-ko/types';
import { Injectable, Signal, WritableSignal } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ThingMapper } from '../../mappers/thing.mapper';
import { MetaDataModel } from '../../../core/domain/metadata.type';
import { AlkoThing } from 'src/app/core/domain/thing.type';
import {
  RobolinhoStateWriteable,
  RobolinhoState,
} from 'src/app/core/domain/robolinho-state.type';
import { ErrorDescriptionSP } from '@usecases/pim-usecases/resolve-error-description.usecase';
import { MetaDatas } from '@usecases/get-metadata-of-device.usecase';
// import { Result } from './../../../core/types/types';
// import { BookmarkRequest } from 'src/app/data/requests/bookmark.request';

@Injectable({ providedIn: 'root' })
export abstract class IDeviceInteractor {
  public devices: {
    [thingName: string]: {
      thing: WritableSignal<AlkoThing>;
      sub: Subscription;
    };
  } = {};

  protected historyBook: {
    [thingName: string]: {
      [attributeName: string]: WritableSignal<HistoryData[]>;
    };
  } = {};

  abstract getDeviceSignal(thingName: string): Signal<AlkoThing>;
  abstract refreshDeviceState(thingName: string): void;
  abstract refreshHistoryData(thingName: string, attributeName: string): void;

  //  OLD
  abstract getDeviceList(withCached: boolean): Observable<AlkoThing[]>;
  abstract getDevice(thingName: string): Observable<AlkoThing>;

  //  OTHERS
  abstract subscribeToDevice(thingName: string): Observable<any>;
  abstract unsubscribeToDevice(thingName: string): void;
  abstract getDeviceMetadata(thingName: string): Observable<MetaDatas>; //  is observeable the correct response here? or should it better be a promise?
  abstract createDeviceMetadata(
    thingName: string,
    attributeName: string,
    data: any,
  ): Observable<MetaDataModel>; //  is observeable the correct response here? or should it better be a promise?
  abstract getHistoryData(
    thingName: string,
    attributeName: string,
  ): Signal<HistoryData[]>;

  abstract updateDesiredState(
    thingName: string,
    desiredState: Partial<RobolinhoStateWriteable>,
  ): Observable<Partial<RobolinhoState>>;

  abstract getProductInformation( //  is observeable the correct response here? or should it better be a promise?
    articleNumber: string,
  ): Observable<PimInformation>;

  abstract removeAccessToDevice(
    thingName: string,
    accessId: string,
  ): Observable<Access>;

  abstract getErrorDescription(
    thingType: ThingType,
    errorCode: number,
  ): Observable<ErrorDescriptionSP>;

  abstract getErrorDescriptionsOfType(
    thingType: ThingType,
  ): Observable<{ [errorCode: string]: ErrorDescriptionSP }>;

  // abstract getLatestRobolinhoFirmwareVersion(
  //   component: 'mainboard' | 'communication',
  //   articleNumber: string,
  //   localization?: 'SW' | 'NE',
  // ): Observable<string>;
  abstract getLatestRobolinhoFirmwareVersion(
    articleNumber: string,
    localization?: 'SW' | 'NE',
  ): Observable<string>;
}
