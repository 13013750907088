import { Injectable } from "@angular/core";
import { UseCase } from "../../base/use-case";
import { Observable, of } from "rxjs";
import { IDeviceRepository } from "@repositories/device.repository";

type RemoveAccessUsecaseInput = {
  thingName: string;
  accessId: string;
}

@Injectable({
  providedIn: 'root',
})
export class RemoveAccessUsecase implements UseCase<RemoveAccessUsecaseInput, any> {
  constructor(private deviceRepository: IDeviceRepository) {}

  execute(
    _input: RemoveAccessUsecaseInput,
  ): Observable<any> {
    this.deviceRepository.removeAccessToDevice(_input.thingName, _input.accessId);
    return of();
  }
}