<mat-toolbar class="alko-toolbar">
  <div class="image-container">
    <img
      src="/assets/images/AL-KO_logo.png"
      alt="AL-KO Logo"
      class="brand-logo"
    />
    <!-- <img src="/assets/images/alko.png" alt="AL-KO Logo" class="brand-logo"> -->
  </div>

  <span class="app-title">Service Dashboard</span>
  <!-- <h2 class="app-title">Service Dashboard</h2> -->
  <span style="height: 70%; margin-left: 30px">
    <app-sba-loading-spinner
      [isLoading]="isLoading()"
    ></app-sba-loading-spinner>
  </span>

  <app-main-search></app-main-search>

  <!-- <span>pending? {{ isLoading() }}</span> -->

  <!-- <span>{{ requests }}</span> -->
  <span class="spacer"></span>

  <button mat-icon-button class="alko-button">
    <mat-icon fontSet="material-symbols-outlined" class="alko-icon-button"
      >notifications</mat-icon
    >
  </button>

  <button mat-icon-button class="alko-button">
    <mat-icon
      fontSet="material-symbols-outlined"
      class="alko-icon-button"
      (click)="onOpenSettingsDialg()"
      >settings</mat-icon
    >
  </button>

  <!-- Username Button -->
  <button mat-button [matMenuTriggerFor]="userMenu">
    <span class="username">{{ userName }}</span>
    <mat-icon fontSet="material-symbols-outlined" class="alko-icon-button"
      >person</mat-icon
    >
  </button>

  <!-- User Menu -->
  <mat-menu #userMenu="matMenu">
    <!-- <button mat-menu-item>
      <mat-icon fontSet="material-symbols-outlined">person</mat-icon>
      <span>Profile</span>
    </button> -->
    <button mat-menu-item (click)="onLogout()">
      <mat-icon fontSet="material-symbols-outlined">logout</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>
