import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { IMetaRepository } from '../repositories/meta-information.repository';
import { MetaDataModel } from '../domain/metadata.type';

export type MetaDatas = { [attributeName: string]: any };

@Injectable({
  providedIn: 'root',
})
export class GetMetaDataOfDeviceUsecase implements UseCase<string, MetaDatas> {
  constructor(private metaRepo: IMetaRepository) {}

  execute(thingName: string): Observable<MetaDatas> {
    return this.metaRepo.getMetadata(thingName).pipe(
      map((m) => {
        return this.createHashmapFromArray(m.queriedAttributes);
      }),
    );
  }

  private createHashmapFromArray(data: MetaDataModel[]): MetaDatas {
    return data.reduce((map, obj) => {
      map[obj.attribute] = obj.attributeValue;
      return map;
    }, {});
  }
}
