import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { IMetaRepository } from '../repositories/meta-information.repository';
import { MetaDataModel } from '../domain/metadata.type';

type CreateMetaDataUsecaseInput = {
  thingName: string;
  // metadata: MetaDataModel;
  attributeName: string;
  data: any;
};

@Injectable({
  providedIn: 'root',
})
export class CreateMetaDataUsecase
  implements UseCase<CreateMetaDataUsecaseInput, any>
{
  constructor(private metaRepo: IMetaRepository) {}

  execute(input: CreateMetaDataUsecaseInput): Observable<any> {
    console.log('[CreateMetaDataUsecase] input:', input);
    return this.metaRepo.createMetadata(input.thingName, {
      [input.attributeName]: input.data,
    });
  }
}
