import {
  Injectable,
  signal,
  Signal,
  WritableSignal,
  computed,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HttpLoadingService {
  private activeRequests: WritableSignal<number> = signal(0);
  public isLoading: Signal<boolean> = computed(() => this.activeRequests() > 0);

  increment(): void {
    this.activeRequests.update((v) => v + 1);
  }

  decrement(): void {
    this.activeRequests.update((v) => v - 1);
  }
}
