import { PimInformation, Thing } from '@al-ko/types';
import { Observable } from 'rxjs';
import { ThingState } from '../domain/thingstate.type';
import { FotaStatus } from '../domain/fota-status.enum';
import { AlkoThing } from '../domain/thing.type';
import {
  RobolinhoStateWriteable,
  RobolinhoState,
} from '../domain/robolinho-state.type';

export abstract class IDeviceRepository {
  abstract getAllDevices(): Observable<AlkoThing[]>;
  abstract getDeviceById(thingName: string): Observable<AlkoThing>;
  abstract getReportedStateOfDevice(thingName: string): Observable<ThingState>;
  abstract setDesiredState(
    thingName: string,
    desiredState: Partial<RobolinhoStateWriteable>,
  ): Observable<Partial<RobolinhoState>>;
  abstract getFotaStatus(
    thingName: string,
  ): Observable<{ fotaStatus: FotaStatus; targetVersion: string }>;
  abstract removeAccessToDevice(thingName: string, accessId: string): void;
  abstract getLatestRobolinhoMainboardVersion(
    articleNumber: string,
    localization: 'SW' | 'NE',
  ): Observable<string>;
  abstract getLatestRobolinhoMainboardVersionAxios(
    articleNumber: string,
    localization: 'SW' | 'NE',
  ): Promise<string>;
}
