import {
  catchError,
  firstValueFrom,
  map,
  Observable,
  of,
  throwError,
} from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ThingState } from '../../../core/domain/thingstate.type';
import { IDeviceRepository } from '../../../core/repositories/device.repository';
import { demoThings } from '../../../core/demo/things.demo';
import { environment } from '../../../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { FotaStatus } from '../../../core/domain/fota-status.enum';
import { AlkoThing, ThingWeb } from 'src/app/core/domain/thing.type';
import { ThingMapper } from '@mappers/thing.mapper';
import axios from 'axios';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class DeviceWebRepository extends IDeviceRepository {
  mapper = new ThingMapper();

  private authService = inject(OAuthService);
  constructor(private http: HttpClient) {
    super();
  }

  setDesiredState(
    thingName: string,
    desiredState: any,
  ): Observable<ThingState> {
    const url = `${environment.baseUrl}/iot/things/${thingName}/state/desired`;
    return this.http.patch(url, desiredState);
  }

  getReportedStateOfDevice(thingName: string): Observable<ThingState> {
    throw new Error('Method not implemented.');
  }

  getAllDevices(): Observable<AlkoThing[]> {
    const url = `${environment.baseUrl}/iot/things?pimInfo=true`;
    // const params = new HttpParams().set('pimInfo', 'true');
    return this.http.get<ThingWeb[]>(url).pipe(
      map((x) => {
        return x.map(this.mapper.mapFrom);
      }),
    );
  }

  getDeviceById(thingName: string): Observable<AlkoThing> {
    const url = `${environment.baseUrl}/iot/things/${thingName}`;
    return this.http.get<ThingWeb>(url).pipe(map(this.mapper.mapFrom));
  }

  override getFotaStatus(
    thingName: string,
  ): Observable<{ fotaStatus: FotaStatus; targetVersion: string }> {
    throw new Error('Method not implemented.');
  }

  override async removeAccessToDevice(
    thingName: string,
    accessId: string,
  ): Promise<void> {
    const url = `${environment.baseUrl}/iot/things/${thingName}/accesses/${accessId}`;
    await firstValueFrom(this.http.delete(url));
  }

  override getLatestRobolinhoMainboardVersion(
    articleNumber: string,
    localization?: 'SW' | 'NE',
  ): Observable<string> {
    const url = `${environment.baseUrl}/firmware/${articleNumber}/mainboard/${localization || 'SW'}/latest.ota`;
    return this.http
      .head<any>(url, {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
      })
      .pipe(
        map((response) => {
          const version = response.headers.get('Version');
          if (version) {
            return version.substring(0, 5);
          }
          return 'ERROR';
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(
            '[deviceWebRepo][getLatestRobolinhoMainboardVersion] Error:',
            error,
          );
          return throwError(() => new Error('Failed to get latest version'));
        }),
      );
  }

  override async getLatestRobolinhoMainboardVersionAxios(
    articleNumber: string,
    localization?: 'SW' | 'NE',
  ): Promise<string> {
    const url = `${environment.baseUrl}/firmware/${articleNumber}/mainboard/${localization}/latest.ota`;
    const response = await axios.head(url, {
      headers: { Authorization: `Bearer ${this.authService.getAccessToken()}` },
    });
    return response.headers['Version'];
  }
}
