import { AfterViewInit, Component } from '@angular/core';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { OauthConfig } from '../oauth.config';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

const AUTH_STORAGE_ITEMS: string[] = [
  'access_token',
  'access_token_stored_at',
  'expires_at',
  'granted_scopes',
  'id_token',
  'id_token_claims_obj',
  'id_token_expires_at',
  'id_token_stored_at',
  'nonce',
  'PKCE_verifier',
  'refresh_token',
  'session_state',
];

@Component({
  selector: 'app-login-page',
  imports: [MatButtonModule, CommonModule],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
  providers: [],
})
export class LoginPageComponent implements AfterViewInit {
  constructor(
    private oauthService: OAuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.oauthService.configure(OauthConfig);
    // this.oauthService.setupAutomaticSilentRefresh({}, 'access_token');
    this.oauthService.loadDiscoveryDocumentAndTryLogin();

    if (this.oauthService && this.oauthService.events) {
      this.oauthService.events.subscribe((event) => {
        if (event instanceof OAuthErrorEvent) {
          console.error('[LoginPageComponent] OAuthErrorEvent Object:', event);
        } else if (event.type == 'token_refresh_error') {
          console.error('[LoginPageComponent] token_refresh_error:', event);
          AUTH_STORAGE_ITEMS.map((item: string) => {
            console.error(
              '[LoginPageComponent] remove session storage stuff',
              item,
            );
            sessionStorage.removeItem(item);
          });
        } else {
          console.warn('OAuthEvent Object:', event);
          // console.log('oauthevent:', JSON.stringify(event, undefined, 2));
        }
      });

      this.oauthService.events
        .pipe(filter((e) => e.type === 'token_received'))
        .subscribe((_) => this.oauthService.loadUserProfile());
    }

    const code = this.route.snapshot.queryParams['code'];
    console.log('code:', JSON.stringify(code, undefined, 2));

    if (this.oauthService.hasValidAccessToken()) {
      this.router.navigate(['pages']);
    } else {
      this.waitAndNavigate();
    }
    AUTH_STORAGE_ITEMS.map((item: string) => {
      sessionStorage.removeItem(item);
    });
  }

  public delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async waitAndNavigate() {
    await this.delay(1000);
    console.log(
      'validaccessstoken now?',
      this.oauthService.hasValidAccessToken(),
    );
  }

  public async onLogin() {
    try {
      console.log('...login ...');

      // this.oauthService.initCodeFlow();
      this.oauthService.initLoginFlow();

      console.log('token:', this.oauthService.getAccessToken());
    } catch (ex) {
      console.error('error:', ex);
    }
  }
}
