export const environment = {
  oauthCallbackUrl: 'https://service.dev.alko-garden.net/login-callback',
  postLogoutUrl: 'https://service.dev.alko-garden.net/login',

  loginUrl: 'https://idp.staging.alko-garden.net/connect/authorize',
  logoutUrl: 'https://idp.staging.alko-garden.net/connect/endsession',
  tokenEndpoint: 'https://idp.staging.alko-garden.net/connect/token',
  issuer: 'https://idp.staging.alko-garden.net',

  baseUrl: 'https://staging.al-ko.com/v1',
  websocketUrl: 'wss://socket-staging.al-ko.com/v1',
};
