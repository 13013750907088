import { CommonModule } from '@angular/common';
import { Component, Input, input, InputSignal } from '@angular/core';

@Component({
  selector: 'app-sba-loading-spinner',
  templateUrl: './sba-loading-spinner.component.html',
  styleUrls: ['./sba-loading-spinner.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SbaLoadingSpinnerComponent {
  // public isLoading: InputSignal<boolean> = input<boolean>();
  @Input() isLoading: boolean = true;
  constructor() {}
}
