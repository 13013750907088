import { Observable } from 'rxjs';
import {
  IMetaRepository,
  MetaDataResponse,
} from '../../../core/repositories/meta-information.repository';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MetaDataModel } from '../../../core/domain/metadata.type';

@Injectable({
  providedIn: 'root',
})
export class MetaRepository extends IMetaRepository {
  constructor(private http: HttpClient) {
    super();
  }

  override getMetadata(thingName: string): Observable<MetaDataResponse> {
    const url = `${environment.baseUrl}/meta/${thingName}/metadata`;
    return this.http.get<MetaDataResponse>(url);
  }

  override createMetadata(
    thingName: string,
    metadata: { [attributeName: string]: any },
  ): Observable<MetaDataModel> {
    const url = `${environment.baseUrl}/meta/${thingName}/metadata`;
    return this.http.post<MetaDataModel>(url, metadata);
  }
}
