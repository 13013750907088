<!-- TODO: Mobile-Breakpoint switch einbauen mit einbindung mobile-navigation -->
<!-- <div *ngIf="isContentPage" class="desktop-container">
  <app-navigation-menu-desktop></app-navigation-menu-desktop>
</div> -->

<div class="menu-container">
  <mat-nav-list class="alko-menu">
    @for (menuItem of menu; track $index) {
      <a
        class="alko-menu-item-link"
        style="height: 80px"
        routerLink="{{ menuItem.disabled ? '#' : menuItem.url }}"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
        mat-list-item
        disableRipple="true"
        [disabled]="menuItem.disabled || false"
      >
        <div class="alko-menu-item">
          @if (menuItem.icon) {
            <mat-icon
              fontSet="material-symbols-outlined"
              class="alko-menu-item-icon"
              >{{ menuItem.icon }}</mat-icon
            >
          }
          @if (menuItem.svgIcon) {
            <mat-icon
              fontSet="material-symbols-outlined"
              class="alko-menu-item-icon"
              [svgIcon]="menuItem.svgIcon"
            ></mat-icon>
          }

          <span class="alko-menu-item-label">{{ menuItem.label }}</span>
        </div>
      </a>
    }
  </mat-nav-list>
</div>
