import { inject, Injectable } from '@angular/core';
import {
  RobolinhoStateWriteable,
  RobolinhoState,
} from '../../domain/robolinho-state.type';
import { UseCase } from '../../base/use-case';
import { Observable } from 'rxjs';
import { IDeviceRepository } from '@repositories/device.repository';

type UpdateDesiredStateUsecaseInput = {
  thingId: string;
  desiredState: Partial<RobolinhoStateWriteable>;
};

@Injectable({
  providedIn: 'root',
})
export class UpdateDesiredStateUsecase
  implements UseCase<UpdateDesiredStateUsecaseInput, Partial<RobolinhoState>>
{
  private deviceRepository: IDeviceRepository = inject(IDeviceRepository);

  execute(
    input: UpdateDesiredStateUsecaseInput,
  ): Observable<Partial<RobolinhoState>> {
    return this.deviceRepository.setDesiredState(
      input.thingId,
      input.desiredState,
    );
  }
}
