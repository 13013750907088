<div
  class="center"
  style="height: 100%; width: 100%; max-height: 100px; max-width: 100%"
>
  <!-- <div
    #rotatingBox
    [ngClass]="{ rotating: isLoading() === true }"
    class="rotating-box"
  > -->
  <div
    #rotatingBox
    [ngClass]="{ rotating: isLoading == true }"
    class="rotating-box"
  >
    <img src="assets/images/sba-icon.png" class="sba-icon" />
  </div>
</div>
