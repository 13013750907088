import { Component, effect, inject, OnInit, Signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from '../../../services/user.service';
import { MainSettingsDialogComponent } from './main-settings-dialog/main-settings-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MainSearchComponent } from './main-search/main-search.component';
import { HttpClient } from '@angular/common/http';
import { ThingHistoryServiceService } from 'src/app/presenter/services/thing-history-service.service';
import { PendingRequestInterceptor } from 'src/app/presenter/interceptor/pending-request.interceptor';
import { toObservable } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpLoadingService } from 'src/app/presenter/services/http-loading.service';
import { SbaLoadingSpinnerComponent } from '@components/shared/sba-loading-spinner/sba-loading-spinner.component';

@Component({
  selector: 'app-main-toolbar',
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MainSearchComponent,
    SbaLoadingSpinnerComponent,
  ],
  templateUrl: './main-toolbar.component.html',
  styleUrl: './main-toolbar.component.scss',
})
export class MainToolbarComponent implements OnInit {
  readonly dialog = inject(MatDialog);
  public isLoading: Signal<boolean> = inject(HttpLoadingService).isLoading;
  // public requests: Signal<number> =
  //   this.pendingRequestInterceptor.getPendingRequestSignal();

  // readonly dialogRef = inject(MatDialogRef<MainSettingsDialogComponent>);

  public userName: string = '';

  constructor(
    private authService: OAuthService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userName = this.userService.getUserName();
  }

  onLogout() {
    console.log('logging out ...');
    this.authService.logOut(false);
  }

  onOpenSettingsDialg() {
    this.dialog.open(MainSettingsDialogComponent);
  }
}
