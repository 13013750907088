<div class="search-box">
  <span class="icon-wrapper">
    <mat-icon
      fontSet="material-symbols-outlined"
      class="material-icon search-icon"
      >search</mat-icon
    >

    <input
      type="search"
      class="search-input"
      placeholder="Search"
      [(ngModel)]="searchTerm"
      (input)="onInputChange()"
    />
  </span>

  @if (filteredThings.length) {
    <ul class="suggestions">
      @for (thing of filteredThings; track $index) {
        <li (click)="selectSuggestion(thing)">
          <mat-icon
            fontSet="material-symbols-outlined"
            class="alko-suggestion-item-icon"
            [svgIcon]="thing.thingType"
          ></mat-icon>
          {{ thing.accessInformation.accessAlias }}
          @if (thing.accessInformation.issuerEmail) {
            <span class="suggestion-item-owner"
              >({{ thing.accessInformation.issuerEmail }})</span
            >
          }
          <span class="suggestion-item-serialnumber"
            >[{{ thing.thingAttributes.serialNumber }}]</span
          >
        </li>
      }
    </ul>
  }
</div>
