import { HistoryData } from '@al-ko/types';
import { map, Observable } from 'rxjs';
import { IHistoryDataRepository } from '../../../core/repositories/history-data.repository';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HistoryDataRepository extends IHistoryDataRepository {
  constructor(private http: HttpClient) {
    super();
  }

  override getHistoryData(
    thingName: string,
    attributeName: string,
    lastEvalKey: string,
    timestampFrom?: number,
  ): Observable<{ lastEvalKey: string; data: HistoryData[] }> {
    const url = `${environment.baseUrl}/data/things/${thingName}/${attributeName}`;
    const headers = lastEvalKey
      ? {
          'x-pagination': lastEvalKey,
        }
      : {};
    return this.http
      .get<HistoryData[]>(url, {
        headers: headers,
        // params: { sort: 'ascending' },
        observe: 'response',
      })
      .pipe(
        map((x) => {
          return {
            lastEvalKey: x.headers['x-pagination'],
            data: x.body,
          };
        }),
      );
  }
}
