import {
  computed,
  Injectable,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { HttpLoadingService } from '../services/http-loading.service';

@Injectable()
export class PendingRequestInterceptor implements HttpInterceptor {
  constructor(private loadingService: HttpLoadingService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    this.loadingService.increment();
    return next
      .handle(request)
      .pipe(finalize(() => this.loadingService.decrement()));
  }
}
