import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authGuard: CanActivateFn = async (route, state) => {
  const oauthService = inject(OAuthService);
  if (oauthService.hasValidAccessToken()) {
    return true;
  } else {
    console.log(
      '[authGuard] accesstokenexp: ',
      oauthService.getAccessTokenExpiration(),
    );
    console.log('[authGuard] tokenendpoint?', oauthService.tokenEndpoint); //tokenendpoint is not
    console.log('[authGuard] env.tokenendpoint?', environment.tokenEndpoint); //tokenendpoint is not
    oauthService.tokenEndpoint = environment.tokenEndpoint;
    try {
      console.log('[authGuard] try to refresh token');
      console.log('[authGuard] refresh_token:', oauthService.getRefreshToken());
      const r = await oauthService.refreshToken();
      console.log('[authGuard]  refresh token response:', r);
    } catch (ex) {
      console.log('error on refresh:', ex);
    }
    if (oauthService.hasValidAccessToken()) {
      console.log('refresh worked. :-)');
      return true;
    }
    console.log('refresh has not worked. :-(. let it through anyway');
    return true;
    // const redirectUrl: UrlTree = inject(Router).parseUrl('/login');
    // return redirectUrl;
  }
};
