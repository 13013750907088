import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';
import { IPimRepository } from '../../repositories/pim.repository';
import { IStorageRepository } from '../../repositories/storage.repository';
import { ErrorDescription } from '../../domain/error-description.type';
import { UseCase } from '../../base/use-case';

export type ErrorDescriptionSP = ErrorDescription & {
  actionsSP: string[];
};

export type ErrorDescriptions = {
  [thingType: string]: { [errorCode: number]: ErrorDescriptionSP };
};

@Injectable({
  providedIn: 'root',
})
export class ResolveErrorDescriptionsUsecase
  implements UseCase<undefined, ErrorDescriptions>
{
  private errorDescriptions: ErrorDescriptions;

  constructor(
    private pimRepository: IPimRepository,
    private storage: IStorageRepository,
  ) {}

  execute(): Observable<ErrorDescriptions> {
    return this.queryTranslations().pipe(
      map((translations) => {
        this.prepareData(translations);
        return this.errorDescriptions;
      }),
    );
  }

  private queryTranslations(): Observable<{
    [key: string]: string;
  }> {
    return this.pimRepository.getTranslations('SmartDeviceErrorCodes');
  }

  private prepareData(translations: { [key: string]: string }): void {
    this.errorDescriptions = {};
    for (const key in translations) {
      const value = translations[key];
      const splitted = key.split('_');

      let thingType, type, errorCode;
      if (splitted.length == 3) {
        thingType = splitted[0];
        type = splitted[1];
        errorCode = splitted[2];
      } else {
        thingType = splitted[0];
        type = splitted[1] + splitted[2];
        errorCode = splitted[3];
      }

      if (!this.errorDescriptions[thingType]) {
        this.errorDescriptions[thingType] = {};
      }
      if (!this.errorDescriptions[thingType][errorCode]) {
        this.errorDescriptions[thingType][errorCode] = {
          code: errorCode,
          title: '',
          causes: [],
          actions: [],
          actionsSP: [],
        };
      }

      switch (type) {
        case 'desc':
          this.errorDescriptions[thingType][errorCode].title = value;
          break;
        case 'action':
          this.errorDescriptions[thingType][errorCode].actions =
            value == undefined ? [] : value.split('|').map((v) => v.trim());
          break;
        case 'cause':
          this.errorDescriptions[thingType][errorCode].causes =
            value == undefined ? [] : value.split('|').map((v) => v.trim());
          break;
        case 'actionsp':
          this.errorDescriptions[thingType][errorCode].actionsSP =
            value == undefined ? [] : value.split('|').map((v) => v.trim());
          break;
        default:
          break;
      }
    }
  }
}
