import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { IDeviceRepository } from '@repositories/device.repository';
import { UseCase } from '../../base/use-case';

type GetLatestRobolinhoMainboardVersionInput = {
  articleNumber: string;
  localization?: 'SW' | 'NE';
};

@Injectable({
  providedIn: 'root',
})
export class GetLatestRobolinhoMainboardVersion
  implements UseCase<GetLatestRobolinhoMainboardVersionInput, string>
{
  constructor(private deviceRepo: IDeviceRepository) {}

  execute(input: GetLatestRobolinhoMainboardVersionInput): Observable<string> {
    return this.deviceRepo.getLatestRobolinhoMainboardVersion(
      input.articleNumber,
      input.localization,
    );
  }
}
